export default {
  data() {
    return {
      PAGE_SIZE: 15, // 頁面大小常數
      items: [], // 資料內容
      filterOptions: {}, // 初始化為空對象
      filters: {}, // 篩選條件
      currentPage: 1,
      totalPages: 1,
      loading: false, // 加載狀態
      sortOrder: "desc", // 預設排序方向
      model: null,
    };
  },
  methods: {
    async withLoading(callback) {
      try {
        this.loading = true;
        await callback();
      } catch (error) {
        console.error("Error:", error.message);
      } finally {
        this.loading = false;
      }
    },
    generateQueryParams(fieldName) {
      const filterParams = Object.keys(this.filters)
        .filter(key => this.filters[key] !== '') // 過濾掉空的篩選條件
        .map(key => `${key}=${encodeURIComponent(this.filters[key])}`) // 組合篩選參數
        .join('&');

      // 新增 model 篩選參數
      const modelParam = this.model ? `model=${encodeURIComponent(this.model)}` : '';
      const ordering = this.sortOrder === "asc" ? fieldName : `-${fieldName}`;

      // 組合完整的查詢參數
      return `&ordering=${ordering}${filterParams ? `&${filterParams}` : ''}${modelParam ? `&${modelParam}` : ''}`;
    },

    // 切換排序方向
    toggleSortOrder(fieldName = "created_date") {
      this.sortOrder = this.sortOrder === "asc" ? "desc" : "asc";
      this.fetchPageData(this.currentPage, fieldName);
    },
    // 切換頁面
    changePage(page) {
      if (page > 0 && page <= this.totalPages) {
        this.currentPage = page;
        this.fetchPageData(page);
      }
    },
    resetFilters() {
      Object.keys(this.filters).forEach(key => (this.filters[key] = '')); // 清空所有篩選條件
      this.resetPagination();
    },
    resetPagination() {
      this.currentPage = 1;
      this.sortOrder = "desc";
      this.applyFilters();
    },
    // 應用篩選條件
    async applyFilters() {
      await this.withLoading(async () => {
        this.currentPage = 1;
        await this.fetchPageData(this.currentPage);
      });
    },
    // 更新 fetchPageData
    async fetchPageData(page, fieldName = "created_date") {
      await this.withLoading(async () => {
        const queryParams = this.generateQueryParams(fieldName);
        const response = await this.fetchDataFunction(page, queryParams);

        if (response.data.results) {
          // 單層結構數據（默認行為）
          this.items = response.data.results.map(item => ({
            ...item,
            type: item.type || "", // 確保添加 `type` 字段
          }));
          this.totalPages = Math.ceil(response.data.count / this.PAGE_SIZE);
        } else if (Object.keys(response.data).length > 0) {
          // 多層結構數據（針對多模型數據）
          if (this.filters.model && response.data[this.filters.model]) {
            const modelData = response.data[this.filters.model];
            this.items = modelData.results.map(item => ({
              ...item,
              type: this.filters.model, // 添加 `type` 為當前模型
            }));
            this.totalPages = Math.ceil(modelData.count / this.PAGE_SIZE);
          } else {
            this.items = Object.entries(response.data)
              .flatMap(([modelType, modelData]) =>
                (modelData.results || []).map(item => ({
                  ...item,
                  type: modelType, // 添加 `type` 為每個模型的類型
                }))
              );
            this.totalPages = 1; // 如果不分頁，設為 1
          }
        } else {
          // 空數據
          this.items = [];
          this.totalPages = 1;
        }
      });
    }

  },
};
