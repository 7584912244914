<template>
  <div class="w-full mx-auto mt-1">
    <!-- Loading 提示 -->
    <div class="pagination flex justify-left items-center my-2">
      <button @click="changePage(currentPage - 1)" :disabled="currentPage === 1"
        class="px-4 py-2 mx-1 bg-gray-200 rounded">
        Prev
      </button>
      <span>&nbsp;{{ currentPage }} / {{ totalPages }}&nbsp;</span>
      <button @click="changePage(currentPage + 1)" :disabled="currentPage === totalPages"
        class="px-4 py-2 mx-1 bg-gray-200 rounded">
        Next
      </button>
      <span v-if="loading" class="text-blue-500 font-semibold">&nbsp; Loading... </span>
    </div>

    <!-- 篩選選項 -->
    <div class="flex flex-wrap gap-4 items-end mb-4">
      <div v-for="field in filterableFields" :key="field.key" class="flex flex-col">
        <label :for="field.key" class="font-semibold mb-1">{{ field.label }}</label>
        <select v-model="filters[field.key]" @change="applyFilters" class="border p-2 rounded w-full">
          <option value="">全部</option>
          <option v-for="option in filterOptions[field.key]" :key="option" :value="option">
            {{ option }}
          </option>
        </select>
      </div>
      <!-- 日期範圍選擇 -->
      <div class="flex items-center">
        <label for="start_date" class="font-semibold mr-2">Start Date:</label>
        <input type="date" id="start_date" v-model="filters.start_date" @change="applyFilters"
          class="border p-2 rounded" />
      </div>
      <div class="flex items-center">
        <label for="end_date" class="font-semibold mr-2">End Date:</label>
        <input type="date" id="end_date" v-model="filters.end_date" @change="applyFilters" class="border p-2 rounded" />
      </div>
      <!-- Reset 按鈕 -->
      <button @click="resetFilters" class="px-4 py-2 bg-red-500 text-white rounded hover:bg-red-600 h-10">
        Reset
      </button>
      <!-- 排序按鈕 -->
      <button @click="toggleSortOrder('date_joined')"
        class="px-4 py-2 bg-blue-500 text-white rounded hover:bg-blue-600 h-10">
        Sort by Created: {{ sortOrder === 'asc' ? '🔼' : '🔽' }}
      </button>
    </div>

    <!-- 表格 -->
    <div class="table-responsive">
      <table class="w-full border-collapse border border-gray-300">
        <thead>
          <tr class="bg-gray-100 text-center">
            <th v-for="field in fields" :key="field.key" class="py-2 px-4">
              {{ field.label }}
            </th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="item in sortedItems" :key="item.id" class="border-b">
            <td v-for="field in fields" :key="field.key" class="py-2 px-4">
              <template v-if="field.key === 'functions'">
                <div class="flex flex-wrap gap-2 sm:gap-4 sm:flex-nowrap justify-center">
                  <button :disabled="!canShowActionButtons(item)" @click="handleUpdate(item.id)"
                    class="w-full sm:w-auto px-4 py-2 bg-green-500 text-white rounded hover:bg-green-600 disabled:bg-gray-400 text-center">
                    Update
                  </button>
                  <button :disabled="!canShowActionButtons(item)" @click="handleDelete(item)"
                    class="w-full sm:w-auto px-4 py-2 bg-red-500 text-white rounded hover:bg-red-600 disabled:bg-gray-400 text-center">
                    Delete
                  </button>
                </div>
              </template>
              <template v-else>
                {{ item[field.key] }}
              </template>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>
<script>
import { userLevel, saveEventLog } from "@/utils";
import FilterablePaginationMixin from "@/mixins/FilterablePaginationMixin";
import { fetchUsers, deleteUser, fetchUsersByOrganization, fetchUserFilterOptions } from "@/api/users";


export default {
  mixins: [FilterablePaginationMixin], // 引入共用 Mixin
  data() {
    return {
      PAGE_SIZE: 15, // 頁面大小
      fields: [
        { key: 'date_joined', label: 'Created' },
        { key: 'organization_name', label: 'Organization' },
        { key: 'username', label: 'Username' },
        { key: 'role', label: 'Role' },
        { key: 'functions', label: 'Functions' },
      ],
      filterableFields: [
        { key: 'organization_name', label: 'Organization' },
        { key: 'role', label: 'Role' },
      ],
      filters: {
        organization_name: '',
        role: '',
        start_date: '',
        end_date: '',
      },
    }
  },
  methods: {
    async fetchDataFunction(page, queryParams) {
      const user = this.$store.state.user;
      const currentUserLevel = userLevel(user.role);

      console.log(user);

      //Merdury super & superAdmin
      const superUser = currentUserLevel == 0 || (currentUserLevel == 1 && user.organization == "Merdury Co.")

      const response = superUser
        ? await fetchUsers(page, queryParams)
        : await fetchUsersByOrganization(user.organization_id, page, queryParams);

      console.log(response.data.results);


      return response;
    },
    async fetchFilterOptions() {
      const user = this.$store.state.user;
      const currentUserLevel = userLevel(user.role);
      console.log(currentUserLevel);

      // 判斷是否為 SuperUser or SuperAdmin
      const isSuperUser = currentUserLevel === 0 || currentUserLevel === 1;


      try {
        // 獲取篩選選項
        const response = isSuperUser
          ? await fetchUserFilterOptions()
          : await fetchUserFilterOptions(user.organization_id);

        if (response.data) {
          // 處理篩選選項，過濾角色等級高於當前用戶的角色
          if (response.data.role) {
            const filteredRoles = response.data.role.filter(role => {
              const roleLevel = userLevel(role);
              return roleLevel >= currentUserLevel && roleLevel != 0;
            });
            response.data.role = filteredRoles; // 更新角色選項
          }

          this.filterOptions = response.data; // 更新到篩選選項
        }
      } catch (error) {
        console.error("Error fetching filter options:", error.message);
      }
    },
    async handleDelete(item) {
      // 顯示確認訊息
      const isConfirmed = confirm("Are you sure you want to delete this user?");
      if (!isConfirmed) return; // 若使用者選擇取消則中止

      const currentUserId = this.$store.state.user.id;

      try {
        this.loading = true;
        const details = `organization: ${item.organization_name}
username: ${item.username}
role: ${item.role}`;
        await deleteUser(item.id);
        await saveEventLog(
          currentUserId,
          "User Management",
          "Delete",
          "User",
          item.username,
          "User Delete Successful",
          "Success",
          details
        );

        this.applyFilters(); // 刪除後重新加載數據
        this.fetchFilterOptions();
      } catch (error) {
        await saveEventLog(
          currentUserId,
          "User Management",
          "Delete",
          "User",
          `(${item.organization_name})${item.username}`,
          "User Delete Failed",
          "Success"
        );
      } finally {
        this.loading = false;
      }
    },
    handleUpdate(userId) {
      this.$router.push({ name: "UpdateUser", params: { userId } });
    },
    canShowActionButtons(item) {
      const user = this.$store.state.user; // 當前用戶的角色
      const roleLevel = userLevel(user.role); // 當前用戶的角色等級

      // 確保 `item.role` 是合法角色，並比較角色等級
      const itemLevel = userLevel(item.role);

      // 只有當 `userLevel` 大於等於 `itemLevel` 時，按鈕可顯示
      return roleLevel < itemLevel && item.id != user.id;
    },
  },
  async mounted() {
    this.loading = true;
    await this.fetchFilterOptions();
    await this.applyFilters(); // 初始加載數據
    this.loading = false;
  },
  computed: {
    sortedItems() {
      return [...this.items].sort((a, b) => {
        const dateA = new Date(a.date_joined);
        const dateB = new Date(b.date_joined);
        return this.sortOrder === "asc" ? dateA - dateB : dateB - dateA;
      });
    },
  },
};
</script>

<style scoped>
@import '@/assets/css/table-styles.css';
</style>