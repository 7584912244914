<template>
  <div class="w-full mx-auto mt-1">
    <!-- Pagination -->
    <div class="pagination flex justify-left items-center my-2">
      <button @click="changePage(currentPage - 1)" :disabled="currentPage === 1"
        class="px-4 py-2 mx-1 bg-gray-200 rounded">
        Prev
      </button>
      <span>&nbsp;{{ currentPage }} / {{ totalPages }}&nbsp;</span>
      <button @click="changePage(currentPage + 1)" :disabled="currentPage === totalPages"
        class="px-4 py-2 mx-1 bg-gray-200 rounded">
        Next
      </button>
      <span v-if="loading" class="text-blue-500 font-semibold">&nbsp; Loading... </span>
    </div>

    <!-- Filter Options -->
    <div class="flex flex-wrap gap-4 items-end mb-4">
      <div v-for="field in filterableFields" :key="field.key" class="flex flex-col">
        <label :for="field.key" class="font-semibold mb-1">{{ field.label }}</label>
        <select v-model="filters[field.key]" @change="applyFilters" class="border p-2 rounded w-full">
          <option value="">全部</option>
          <option v-for="option in filterOptions[field.key]" :key="option" :value="option">
            {{ option }}
          </option>
        </select>
      </div>
      <!-- Date Range Filters -->
      <div class="flex items-center">
        <label for="start_date" class="font-semibold mr-2">Start Date:</label>
        <input type="date" id="start_date" v-model="filters.start_date" @change="applyFilters"
          class="border p-2 rounded" />
      </div>
      <div class="flex items-center">
        <label for="end_date" class="font-semibold mr-2">End Date:</label>
        <input type="date" id="end_date" v-model="filters.end_date" @change="applyFilters" class="border p-2 rounded" />
      </div>
      <!-- Reset Button -->
      <button @click="resetFilters" class="px-4 py-2 bg-red-500 text-white rounded hover:bg-red-600 h-10">
        Reset
      </button>
    </div>
    <!-- Table -->
    <div class="table-responsive">
      <table class="w-full border-collapse border border-gray-300">
        <thead>
          <tr class="bg-gray-100 text-center">
            <th v-for="field in fields" :key="field.key" class="py-2 px-4">
              {{ field.label }}
            </th>
            <th class="py-2 px-4">Details</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(log, index) in sortedItems" :key="log.id || index" class="border-b hover:bg-gray-100">
            <td class="py-2 px-4">{{ log.created_date }}</td>
            <td class="py-2 px-4">{{ log.event.type }}</td>
            <td class="py-2 px-4">{{ log.event.action }}</td>
            <td class="py-2 px-4">{{ log.event.details.name }}</td>
            <td class="py-2 px-4">{{ log.result }}</td>
            <td class="py-2 px-4">{{ log.organization }}</td>
            <td class="py-2 px-4">{{ log.user.username }}</td>
            <td class="py-2 px-4 text-center">
              <button @click="openDetails(log.event.details)" class="text-blue-500 underline">View</button>
            </td>
          </tr>
          <!-- Fill Empty Rows -->
          <tr v-for="i in PAGE_SIZE - sortedItems.length" :key="'empty-' + i" class="border-b">
            <td class="py-2 px-4" colspan="8">&nbsp;</td>
          </tr>
        </tbody>
      </table>
    </div>

    <!-- Modal -->
    <div v-if="showDetailsModal" class="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
      <div class="bg-white p-6 rounded shadow-md max-w-lg w-full">
        <h2 class="text-xl font-bold mb-4">Details</h2>
        <pre v-if="selectedDetails && Object.keys(selectedDetails).length > 0"
          class="whitespace-pre-wrap">{{ JSON.stringify(selectedDetails, null, 2).replace(/\\n/g, '\n') }}</pre>
        <div class="flex justify-end mt-4">
          <button @click="closeDetails" class="bg-blue-500 text-white px-4 py-2 rounded hover:bg-blue-600">
            Close
          </button>
        </div>
      </div>
    </div>
  </div>
</template>






<script>
import FilterablePaginationMixin from "@/mixins/FilterablePaginationMixin";
import { fetchEventLogs, fetchEventLogsByOrganization, fetchEventLogFilterOptions } from "@/api/products";


export default {
  mixins: [FilterablePaginationMixin], // 引入共用 Mixin,
  data() {
    return {
      PAGE_SIZE: 15, // 定義常數頁面大小
      showDetailsModal: false,
      selectedDetails: null,
      fields: [
        { key: "created_date", label: "Created" },
        { key: "event.type", label: "Event Type" },
        { key: "event.action", label: "Action" },
        { key: "event.details.name", label: "Target Name" },
        { key: "result", label: "Result" },
        { key: "organization", label: "Organization" },
        { key: "user", label: "User" }, // 新增 User 篩選選項
      ],
      filterableFields: [
        { key: "event_type", label: "Event Type" },
        { key: "result", label: "Result" },
        { key: "action", label: "Action" },
        { key: "organization", label: "Organization" },
        { key: "user", label: "User" },
      ],
      filters: {
        event_type: "",
        result: "",
        action: "",
        user: "", // User 篩選條件
        organization: "",
        start_date: "",
        end_date: "",
      },
    };
  },
  methods: {
    formatDetails(details) {
      // 確保換行符號以 <br> 呈現
      return details.replace(/\n/g, '<br>');
    },
    openDetails(details) {
      console.log(details.description);

      this.selectedDetails = details.description == null ? "empty" : details.description;
      this.showDetailsModal = true;
    },
    closeDetails() {
      this.showDetailsModal = false;
      this.selectedDetails = null;
    },
    // 定義 API 呼叫函數，傳遞給 Mixin 使用
    async fetchDataFunction(page, queryParams) {
      const user = this.$store.state.user;
      if (user.role === "SUPER") {
        return await fetchEventLogs(page, queryParams); // 全量數據
      } else {
        return await fetchEventLogsByOrganization(user.organization_id, page, queryParams); // 組織範圍數據
      }
    },
    async fetchFilterOptions() {
      try {
        const user = this.$store.state.user;
        var response;
        if (user.role === "SUPER") {
          response = await fetchEventLogFilterOptions();
        } else {
          response = await fetchEventLogFilterOptions(user.organization_id); // 組織範圍數據
        }
        console.log("response", response);
        this.filterOptions = {
          event_type: response.data.event_types || [],
          action: response.data.actions || [],
          result: ["Success", "Failed"], // 添加靜態選項
          user: response.data.users || [],
          organization: response.data.organizations || [], // 組織選項
        };
        console.log("Filter Options:", this.filterOptions);
      } catch (error) {
        console.error("Failed to fetch filter options:", error);
      }
    }
  },
  async mounted() {
    this.loading = true;
    await this.fetchFilterOptions();
    await this.applyFilters(); // 初始加載資料
    this.loading = false;
  },
  computed: {
    // 排序邏輯，根據 sortOrder 進行排序
    sortedItems() {
      return [...this.items].sort((a, b) => {
        const dateA = new Date(a.created_date);
        const dateB = new Date(b.created_date);
        return this.sortOrder === "asc" ? dateA - dateB : dateB - dateA;
      });
    },
  },
};
</script>
