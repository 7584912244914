import { createApp } from "vue";
import { library } from '@fortawesome/fontawesome-svg-core';
import { faFilter } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';

import App from "./App.vue";
// import css
import "./assets/css/app.css";
// import router
import router from "./router";
import store from "./store";
import LoadingIndicator from "./pages/base/LoadingIndicator.vue";
import { resetLogoutTimer } from './utils';

function restoreLoginState() {
    const user = localStorage.getItem('user');
    if (user) {
        store.commit('setUser', JSON.parse(user));
    }
}



library.add(faFilter);
const app = createApp(App);
window.addEventListener('click', resetLogoutTimer);
window.addEventListener('keypress', resetLogoutTimer);

restoreLoginState();
app.component('font-awesome-icon', FontAwesomeIcon);
app.component('loading-indicator', LoadingIndicator);
app.use(router);
app.use(store);
app.mount('#app');