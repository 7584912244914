<template>
  <div class="w-full flex justify-center items-center">
    <form @submit.prevent="handleSubmit" class="flex flex-col px-10 py-6 items-center rounded-xl">
      <h2 class="text-blue-700 text-3xl font-semibold my-4">Login</h2>
      <label for="username" class="text-sm">Username:</label>
      <input type="text" v-model="username" autocomplete="off"
        class="text-center h-8 w-64 round-md border border-slate-300 text-sm bg-transparent outline-blue-600 shadow-sm mb-4"
        required>
      <br>
      <label for="password" class="text-sm">Password:</label>
      <input type="password" v-model="password"
        class="text-center h-8 w-64 round-md border border-slate-300 text-sm bg-transparent outline-blue-600 shadow-sm mb-4"
        required autocomplete="current-password">
      <br>
      <button type="submit"
        class="w-full bg-blue-700 h-10 cursor-pointer text-white rounded-md hover:bg-blue-600 hover:outline outline-2 outline-blue-600 outline-offset-2 text-sm">Login</button>
    </form>
  </div>
</template>

<script>
import { showErrorMessage, saveEventLog } from "@/utils.js"
import { loginUser } from '@/api/users.js';
import { startLogoutTimer } from "@/utils.js";

export default {
  data() {
    return {
      username: '', password: ''
    };
  },
  methods: {
    async handleSubmit() {
      try {
        var user;
        const response = await loginUser(this.username, this.password);
        if (response.data.success) {
          user = response.data.user_info;
          if (user.role == "OP") {
            showErrorMessage("Login Failed", "使用者權限不足!");
            return;
          }

          await saveEventLog(
            user.id,
            "User Management",
            "Login",
            "User",
            user.username,
            "User Login Successful",
            "Success"
          );

          startLogoutTimer();
          this.$store.commit('setUser', user);
          this.$store.commit('setActiveMenu', 'Profile');
          this.$router.push({ name: 'Profile' });
        } else {
          showErrorMessage("Login Failed", response.data.message);
        }
      } catch (error) {
        showErrorMessage("Login Failed", "使用者帳號或密碼錯誤!");
      }
    },
  }
};
</script>

<style scoped></style>
