<template>
  <div class="w-full flex justify-center">
    <loading-indicator :isLoading="isLoading"></loading-indicator>
    <form id="register-form" action="" method="POST" @submit.prevent="registerUser"
      class="flex flex-col px-10 py-6 items-center rounded-xl">
      <h2 class="text-blue-700 text-2xl font-semibold my-1">Register</h2>
      <label for="username" class="text-sm">Account:</label>
      <input type="text" name="username" autocomplete="off" v-model="user.username"
        class="text-center h-8 w-full round-md border border-slate-300 text-sm  bg-transparent outline-blue-600 shadow-sm mb-4"
        required>
      <label class="text-sm flex w-full justify-between">
        <div class="w-1/2 pr-2">
          <span>First Name:</span>
          <input type="text" name="first_name" autocomplete="off" v-model="user.firstName"
            class="text-center h-8 w-full rounded-md border border-slate-300 text-sm bg-transparent outline-blue-600 shadow-sm mb-4">
        </div>
        <div class="w-1/2 pl-2">
          <span>Last Name:</span>
          <input type="text" name="last_name" autocomplete="off" v-model="user.lastName"
            class="text-center h-8 w-full rounded-md border border-slate-300 text-sm bg-transparent outline-blue-600 shadow-sm mb-4">
        </div>
      </label>
      <label for="password" class="text-sm">Password:</label>
      <input type="password" name="password" v-model="user.password" autocomplete="off"
        class="text-center h-8 w-full round-md border border-slate-300 text-sm bg-transparent outline-blue-600 shadow-sm mb-4"
        required>

      <label for="password_confirm">Password confirm:</label>
      <input type="password" name="password_confirm" v-model="user.passwordConfirm" autocomplete="off"
        class="text-center h-8 w-full rounded-md border border-slate-300 text-sm  bg-transparent outline-blue-600 shadow-sm mb-4"
        required>

      <label for="email">Email:</label>
      <input type="email" name="email" v-model="user.email"
        class="text-center h-8 w-full rounded-md border border-slate-300 text-sm  bg-transparent outline-blue-600 shadow-sm mb-4"
        required>

      <div>
        <label for="organization">Organization:</label>
        <select v-model="selectedOrganization" class="text-sm mx-1 py-2 px-4 mb-4" required>
          <!-- <option v-if="userLevel != 0" :value="selectedOrganization">{{ organization_name }}</option> -->
          <option v-for="organization in organizations" :key="organization.id" :value="organization.id">
            {{ organization.name }}
          </option>
        </select>
      </div>
      <div id="role" class="text-sm mb-4">
        <label for="role">Role:</label>
        <div class="role-selector flex">
          <div v-for="role in roles" :key="role" class="role-option mr-4">
            <input type="radio" :id="role" :value="role" v-model="user.role">
            <label :for="role">{{ role }}</label>
          </div>
        </div>
      </div>
      <div class="grid grid-cols-2 gap-4 justify-center w-full">
        <button type="submit"
          class="bg-blue-700 h-10 cursor-pointer text-white rounded-md hover:bg-blue-600 hover:outline outline-2 outline-blue-600 outline-offset-2 text-sm">Submit</button>
        <button type="reset"
          class="bg-red-700 h-10 cursor-pointer text-white rounded-md hover:bg-red-600 hover:outline outline-2 outline-red-600 outline-offset-2 text-sm">Reset</button>
      </div>
    </form>
  </div>
</template>
<script>
import { showErrorMessage, showSuccessMessage, userLevel, saveEventLog } from "@/utils";
import { ROLES } from "@/constants";
import { registerUser } from "@/api/users";

import fetchOrganizationsMixin from '@/mixins/fetchOrganizationsMixin';

export default {
  mixins: [fetchOrganizationsMixin],
  data() {
    return {
      selectedOrganization: null,
      userLevel: null,
      organization_name: null,
      organizations: [],
      roles: ROLES,
      isLoading: false,
      user: {
        username: '',
        firstName: '',
        lastName: '',
        password: '',
        passwordConfirm: '',
        email: '',
        organization: '',
        role: '',
      }
    }
  },

  created() {
    this.user.role = ROLES[ROLES.length - 1];
  },

  methods: {
    selectedOrganizationName() {
      // 根據 selectedOrganization 找出對應的 organization.name
      const selected = this.organizations.find(
        (organization) => organization.id === this.selectedOrganization
      );
      return selected ? selected.name : null;
    },
    adjustRolesBasedOnUserLevel() {
      const level = userLevel(this.$store.state.user.role);
      //super
      if (level === 0) {
        this.roles = this.roles.filter(role => role !== 'SUPER');
        //super-admin
      } else if (level === 1) {
        this.roles = this.roles.filter(role => role !== 'SUPER' && role !== 'SUPER ADMIN');
      }
      //admin
      else if (level === 2) {
        this.roles = this.roles.filter(role => role !== 'SUPER' && role !== 'ADMIN' && role !== 'SUPER ADMIN');
      }
      //manager
      else {
        this.roles = ["OP"];
      }
    },
    async registerUser() {
      this.user.organization = this.selectedOrganization;
      const requestData = {
        username: this.user.username,
        first_name: this.user.firstName,
        last_name: this.user.lastName,
        password: this.user.password,
        email: this.user.email,
        organization: this.user.organization,
        role: this.user.role
      };

      this.isLoading = true;
      try {
        const response = await registerUser(requestData);
        console.log(requestData);

        const details = `organization:${this.selectedOrganizationName()}
username:${requestData.username}
role:${requestData.role}`

        if (response.data.success) {
          const userid = this.$store.state.user.id;
          await saveEventLog(
            userid,
            "User Management",
            "Register",
            "User",
            requestData.username,
            "User Registration Successful",
            "Success",
            details
          );

          showSuccessMessage("Registration Successful", "You have been registered successfully!");

          this.$router.push({ name: "UserList" });
        } else {
          await this.RegisterFailed(requestData.username);
        }
      } catch (error) {
        if (error.response && error.response.data) {
          const errorData = error.response.data;
          if (errorData.username) {
            showErrorMessage("Registration Failed", errorData.username[0]);
          } else if (errorData.email) {
            showErrorMessage("Registration Failed", errorData.email[0]);
          } else {
            const message = errorData.message || "An error occurred while updating.";
            showErrorMessage("Registration Failed", message);
          }
        } else {
          showErrorMessage("Registration Failed", "An unknown error occurred.");
        }

        await this.RegisterFailed(requestData.username);

      } finally {
        this.isLoading = false;
      }
    },
    async RegisterFailed(username) {
      const userid = this.$store.state.user.id;
      await saveEventLog(
        userid,
        "User Management",
        "Register",
        "User",
        username,
        "User Registration Failed",
        "Failed"
      );

      showSuccessMessage("Registration Failed", "An error occurred while registering.");
    }
  },
  async mounted() {
    const user = this.$store.state.user;
    this.userLevel = userLevel(user.role);
    if (this.userLevel > 3) {
      showErrorMessage("Access Denied", "Insufficient user permissions!");
      this.$router.go(-1);
      return;
    }

    try {
      this.isLoading = true;
      await this.fetchOrganizations();
      // 獲取當前用戶的 organization_id
      this.selectedOrganization = user.organization_id;
      // 非super or superadmin
      if (this.userLevel >= 2) {
        // 過濾出與當前用戶組織匹配的 organization 
        this.organizations = this.organizations.filter(org => org.id === this.selectedOrganization);
      }

      this.adjustRolesBasedOnUserLevel();
    } catch (error) {
      console.error('Error fetchOrganizations:', error);
      showErrorMessage("fetchOrganizations Failed", "An error occurred while fetchOrganizations.")
    } finally {
      this.isLoading = false;
    }
  }
};
</script>

<style>
@media (max-width: 450px) {
  #register-form {
    padding: 10px;
  }

  form {
    font-size: 12px;
  }

  #register-form input,
  #register-form select,
  #register-form textarea {
    height: 4vh;
    font-size: 0.8rem;
  }
}
</style>
