<template>
  <div class="w-full mx-auto mt-1">
    <!-- Loading 提示 -->
    <div class="pagination flex justify-left items-center my-2">
      <button @click="changePage(currentPage - 1)" :disabled="currentPage === 1"
        class="px-4 py-2 mx-1 bg-gray-200 rounded">
        Prev
      </button>
      <span>&nbsp;{{ currentPage }} / {{ totalPages }}&nbsp;</span>
      <button @click="changePage(currentPage + 1)" :disabled="currentPage === totalPages"
        class="px-4 py-2 mx-1 bg-gray-200 rounded">
        Next
      </button>
      <span v-if="loading" class="text-blue-500 font-semibold">&nbsp; Loading... </span>
    </div>

    <!-- 篩選選項 -->
    <div class="flex flex-wrap gap-4 items-end mb-4">

      <!-- Model 篩選 -->
      <div class="flex flex-col">
        <label for="model" class="font-semibold mb-1">Model</label>
        <select v-model="model" @change="applyFilters" class="border p-2 rounded w-full">
          <option value="">All Models</option>
          <option value="ffu">FFU</option>
          <option value="uv">UV</option>
          <option value="head">Head</option>
          <option value="powder">Powder</option>
        </select>
      </div>
      <!-- 彈出框 -->
      <div v-if="showAddComponentDialog"
        class="fixed inset-0 bg-gray-900 bg-opacity-50 flex justify-center items-center">
        <div class="bg-white p-6 rounded shadow-lg w-96">
          <h3 class="text-lg font-semibold mb-4">Add Component</h3>
          <label for="componentType" class="block font-medium mb-2">Component Type</label>
          <select v-model="selectedComponentType" @change="fetchAvailableComponents"
            class="border p-2 rounded w-full mb-4">
            <option value="">Select Type</option>
            <option value="ffu">FFU</option>
            <option value="uv">UV</option>
            <option value="head">Head</option>
            <option value="powder">Powder</option>
          </select>

          <!-- 顯示選擇到的數量統計 -->
          <div v-if="selectedComponentType" class="text-sm text-gray-700 mb-4">
            <span>
              Components Available:
              <strong>{{ availableComponents.length }}</strong>
            </span>
          </div>

          <!-- 全選和批量添加 -->
          <div v-if="availableComponents.length > 0" class="mb-4">
            <div class="flex items-center mb-2">
              <input type="checkbox" id="selectAll" v-model="selectAll" @change="toggleSelectAll" class="mr-2" />
              <label for="selectAll" class="text-sm text-gray-700">Select All</label>
            </div>

            <label for="component" class="block font-medium mb-2">Available Components</label>
            <select v-model="selectedComponents" @change="handleSelectionChange" class="border p-2 rounded w-full mb-4"
              multiple>
              <option v-for="component in availableComponents" :key="component.serial_number" :value="component">
                {{ component.code }} ({{ component.model_name }})
              </option>
            </select>

            <!-- <button @click="addAllComponents"
              class="px-4 py-2 bg-green-500 text-white rounded hover:bg-green-600 w-full">
              Add All Selected
            </button> -->
          </div>

          <div v-else-if="selectedComponentType" class="text-sm text-gray-600 mb-4">
            No components available for the selected type.
          </div>
          <div class="flex justify-end gap-2">
            <button @click="addAllComponents" class="px-4 py-2 bg-blue-500 text-white rounded hover:bg-blue-600">
              Add Selected
            </button>
            <button @click="closeAddComponentDialog" class="px-4 py-2 bg-gray-300 rounded hover:bg-gray-400">
              Cancel
            </button>
          </div>
        </div>
      </div>

      <button @click="openAddComponentDialog" class="px-4 py-2 bg-green-500 text-white rounded hover:bg-green-600 h-10">
        Add Component
      </button>

      <!-- Reset 按鈕 -->
      <button @click="resetFilters" class="px-4 py-2 bg-red-500 text-white rounded hover:bg-red-600 h-10">
        Reset
      </button>
      <!-- 排序按鈕 -->
      <button @click="toggleSortOrder('created_date')"
        class="px-4 py-2 bg-blue-500 text-white rounded hover:bg-blue-600 h-10">
        Sort by Updated: {{ sortOrder === 'asc' ? '🔼' : '🔽' }}
      </button>
    </div>

    <!-- 表格 -->
    <div class="table-responsive">
      <table class="w-full border-collapse border border-gray-300">
        <thead>
          <tr class="bg-gray-100 text-center">
            <th v-for="field in fields" :key="field.key" class="py-2 px-4">
              {{ field.label }}
            </th>
            <th class="py-2 px-4">Actions</th> <!-- 新增操作列 -->
          </tr>
        </thead>
        <tbody>
          <tr v-for="item in sortedItems" :key="item.id" class="border-b hover-highlight">
            <td v-for="field in fields" :key="field.key" class="py-2 px-4">
              {{ item[field.key] }}
            </td>
            <td class="py-2 px-4 text-center">
              <button v-if="isSuperUser" @click="openModal(item)"
                class="px-4 py-2 bg-red-500 text-white rounded hover:bg-red-600">
                Remove
              </button>
              <span v-else class="text-gray-500">N/A</span>
            </td>

          </tr>
        </tbody>
      </table>
    </div>
  </div>
  <div v-if="showModal" class="fixed inset-0 bg-gray-900 bg-opacity-50 flex justify-center items-center">
    <div class="bg-white p-6 rounded shadow-lg w-96">
      <h3 class="text-lg font-semibold mb-4">Confirm Removal</h3>
      <p class="mb-4">Are you sure you want to remove the component "{{ selectedComponent?.code }}"?</p>
      <div class="flex justify-end gap-2">
        <button @click="confirmRemove" class="px-4 py-2 bg-red-500 text-white rounded hover:bg-red-600">
          Yes, Remove
        </button>
        <button @click="cancelRemove" class="px-4 py-2 bg-gray-300 rounded hover:bg-gray-400">
          Cancel
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import FilterablePaginationMixin from "@/mixins/FilterablePaginationMixin";
import { fetchModelsByProduct, fetchAvailableComponents as fetchAvailableComponentsByType, updateComponentProduct } from "@/api/products";

export default {
  mixins: [FilterablePaginationMixin],
  data() {
    return {
      selectAll: false, // 是否全選
      selectedComponents: [], // 已選中的組件
      selectedComponent: [], // 對應多選模式，初始化為空陣列
      availableComponents: [], // 可用的組件清單
      showModal: false,
      serialNumber: this.$route.params.serialNumber,
      productId: this.$route.params.productId,
      PAGE_SIZE: 15,
      fields: [
        { key: "manufacture_date", label: "Created" },
        { key: "type", label: "Type" },
        { key: "code", label: "Code" },
        { key: "used_time", label: "UsedTime" },
        { key: "total_available_time", label: "AvailableTime" },
      ],
      model: "",
      showAddComponentDialog: false,
      selectedComponentType: "",
    };
  },
  methods: {
    toggleSelectAll() {
      // 全選時，將所有 availableComponents 加入 selectedComponents
      if (this.selectAll) {
        this.selectedComponents = [...this.availableComponents];
      } else {
        // 取消全選時，清空 selectedComponents
        this.selectedComponents = [];
      }
    },
    handleSelectionChange() {
      // 如果已選中的數量等於 availableComponents 的數量，則 selectAll 為 true
      this.selectAll = this.selectedComponents.length === this.availableComponents.length;
    },
    openModal(component) {
      this.selectedComponent = component;
      this.showModal = true;
    },
    async confirmRemove() {
      try {
        const response = await updateComponentProduct(
          this.selectedComponent.serial_number,
          null,
          this.selectedComponent.type
        );
        console.log(response);
        //alert("Component successfully removed!");
        this.applyFilters(); // 刷新数据
      } catch (error) {
        console.error("Error removing component:", error);
        alert("An error occurred while removing the component.");
      } finally {
        this.showModal = false;
      }

    },
    cancelRemove() {
      this.showModal = false; // 关闭模态框
    },
    openAddComponentDialog() {
      if (this.model) {
        // 如果 Model 已選擇，直接設置類型並加載可用組件
        this.selectedComponentType = this.model;
        this.fetchAvailableComponents();
      } else {
        // 否則重置並顯示選擇類型的步驟
        this.selectedComponentType = "";
      }
      this.showAddComponentDialog = true;
    },
    async fetchDataFunction(page, queryParams) {
      try {
        const response = await fetchModelsByProduct(this.serialNumber, page, queryParams);

        return response;
      } catch (error) {
        console.error("Error fetching data:", error.message);
        throw error;
      }
    },


    resetFilters() {
      Object.keys(this.filters).forEach((key) => (this.filters[key] = ""));
      this.model = "";
      this.applyFilters();
    },
    closeAddComponentDialog() {
      this.showAddComponentDialog = false;
      this.selectedComponentType = "";
      this.availableComponents = [];
      this.selectedComponent = null;
    },
    async fetchAvailableComponents() {
      if (!this.selectedComponentType) return;

      try {
        const response = await fetchAvailableComponentsByType(this.selectedComponentType, false);
        this.availableComponents = response.data.options || [];
        this.selectAll = false; // 重置全選狀態
        this.selectedComponents = []; // 清空已選組件
      } catch (error) {
        console.error("Error fetching available components:", error);
        this.availableComponents = [];
      }
    },
    // 批量添加組件
    async addAllComponents() {
      if (!this.selectedComponents.length) {
        alert("No components selected.");
        return;
      }

      if (!this.productId) {
        alert("Product ID is missing. Cannot associate components.");
        return;
      }

      try {
        // 批量更新每個組件
        for (const component of this.selectedComponents) {
          await updateComponentProduct(
            component.serial_number, // 組件 ID
            this.productId, // 產品 ID
            this.selectedComponentType // 組件類型
          );
        }

        alert(`${this.selectedComponents.length} components successfully added!`);
        this.closeAddComponentDialog();
        this.applyFilters(); // 刷新數據
      } catch (error) {
        console.error("Error adding components:", error);
        alert("An error occurred while adding the components.");
      }
    },
    async addComponent() {
      if (!this.selectedComponent || this.selectedComponent.length === 0) {
        alert("Please select a valid component to add.");
        return;
      }

      if (!this.productId) {
        alert("Product ID is missing. Cannot associate component.");
        return;
      }

      if (!this.selectedComponentType) {
        alert("Component type is missing. Please select a type.");
        return;
      }

      try {
        // 處理多選模式，逐一添加選中的組件
        for (const component of this.selectedComponent) {
          const serialNumber = component.serial_number;
          console.log("Adding Component:", serialNumber, this.productId, this.selectedComponentType);

          await updateComponentProduct(
            serialNumber, // 使用選中的組件序列號
            this.productId, // 產品 ID
            this.selectedComponentType // 組件類型
          );
        }

        alert("Selected components successfully added!");
        this.closeAddComponentDialog();
        this.applyFilters();
      } catch (error) {
        console.error("Error updating components:", error);
        alert("An error occurred while updating the components.");
      }
    }
  },

  async mounted() {
    await this.applyFilters();


  },
  computed: {
    sortedItems() {
      if (!this.items || this.items.length === 0) return [];
      return [...this.items].sort((a, b) => {
        const dateA = new Date(a.manufacture_date || a.created_date || 0);
        const dateB = new Date(b.manufacture_date || b.created_date || 0);
        return this.sortOrder === "asc" ? dateA - dateB : dateB - dateA;
      });
    },
    isSuperUser() {
      const user = this.$store.state.user;
      if (user) {
        return user.role == "SUPER" || user.role == "SUPER-ADMIN";
      }
      return false;
    },
  },
};
</script>
