import apiClient from "./auth.js";
import { getHeaders } from "@/utils.js";


export const getCSRFToken = () => {
  const value = `; ${document.cookie}`;
  const parts = value.split(`; csrftoken=`);
  if (parts.length === 2) return parts.pop().split(";").shift();
  return null;
};


export const loginUser = (username, password) => {
  return apiClient.post("/login/", { username, password });
};

export const logoutUser = () => {
  return apiClient.post("/logout/");
};

export const fetchUsers = (page = 1, queryParams = '') =>
  apiClient.get(`/users/?page=${page}${queryParams}`);

export const fetchUsersByOrganization = (organizationId, page, queryParams = '') =>
  apiClient.get(`users/organization/${organizationId}/?page=${page}${queryParams}`);

export const fetchUserFilterOptions = (organization_id = null) => {
  const query = organization_id ? `?organization_id=${organization_id}` : '';
  return apiClient.get(`/users/filter-options/${query}`);
};

export const deleteUser = (userId) =>
  apiClient.delete(`/users/${userId}/`, { headers: getHeaders() });

export const getUser = (userId) =>
  apiClient.get(`/users/${userId}/`, { headers: getHeaders(false) });

export const updateUser = (user) =>
  apiClient.patch(`/users/${user.id}/`, user, { headers: getHeaders() });

export const registerUser = (user) =>
  apiClient.post(`/users/`, user, { headers: getHeaders() });

export const fetchOperations = () =>
  apiClient.get("/operation_logs/");

export const fetchModelsByProduct = (serialNumber, page = 1, queryParams = '') =>
  apiClient.get(`/models_by_product/${serialNumber}/?page=${page}${queryParams}`, {
    headers: getHeaders(false),
  });


export const getTrainingCertId = () =>
  apiClient.get("/generate-serial-number/");

export const fetchTrainingCertId = (trainingCertId) =>
  apiClient.get(`/training_certs/${trainingCertId}/`);

export const createTrainingCert = (data) =>
  apiClient.post(`/training_certs/`, data);

export const updateTrainingCert = (trainingCert) => {
  return apiClient.patch(`/training_certs/${trainingCert.id}/`, trainingCert, { headers: getHeaders() });
}

export const checkSession = () => {
  return apiClient.get(`/check-session/`, { headers: getHeaders(), withCredentials: true });
}