<template>
  <div class="w-full mx-auto mt-1">
    <!-- Loading 提示 -->
    <div class="pagination flex justify-left items-center my-2">
      <button @click="changePage(currentPage - 1)" :disabled="currentPage === 1"
        class="px-4 py-2 mx-1 bg-gray-200 rounded">
        Prev
      </button>
      <span>&nbsp;{{ currentPage }} / {{ totalPages }}&nbsp;</span>
      <button @click="changePage(currentPage + 1)" :disabled="currentPage === totalPages"
        class="px-4 py-2 mx-1 bg-gray-200 rounded">
        Next
      </button>
      <span v-if="loading" class="text-blue-500 font-semibold">&nbsp; Loading... </span>
    </div>

    <!-- 篩選選項 -->
    <div class="flex flex-wrap gap-4 items-end mb-4">
      <div v-for="field in filterableFields" :key="field.key" class="flex flex-col">
        <label :for="field.key" class="font-semibold mb-1">{{ field.label }}</label>
        <select v-model="filters[field.key]" @change="applyFilters" class="border p-2 rounded w-full">
          <option value="">全部</option>
          <option v-for="option in filterOptions[field.key]" :key="option" :value="option">
            {{ option }}
          </option>
        </select>
      </div>
      <!-- 日期範圍選擇 -->
      <div class="flex items-center">
        <label for="start_date" class="font-semibold mr-2">Start Date:</label>
        <input type="date" id="start_date" v-model="filters.start_date" @change="applyFilters"
          class="border p-2 rounded" />
      </div>
      <div class="flex items-center">
        <label for="end_date" class="font-semibold mr-2">End Date:</label>
        <input type="date" id="end_date" v-model="filters.end_date" @change="applyFilters" class="border p-2 rounded" />
      </div>
      <!-- Reset 按鈕 -->
      <button @click="resetFilters" class="px-4 py-2 bg-red-500 text-white rounded hover:bg-red-600 h-10">
        Reset
      </button>
      <!-- 排序按鈕 -->
      <button @click="toggleSortOrder('update_time')"
        class="px-4 py-2 bg-blue-500 text-white rounded hover:bg-blue-600 h-10">
        Sort by Updated: {{ sortOrder === 'asc' ? '🔼' : '🔽' }}
      </button>
    </div>

    <!-- 表格 -->
    <div class="table-responsive">
      <table class="w-full border-collapse border border-gray-300">
        <thead>
          <tr class="bg-gray-100 text-center">
            <th v-for="field in fields" :key="field.key" class="py-2 px-4">
              {{ field.label }}
            </th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="item in sortedItems" :key="item.id" class="border-b hover-highlight"
            @click="goToMachineList(item.id, item.serial_number)" clickable>
            <td v-for="field in fields" :key="field.key"
              :class="field.key === 'status' ? getStatusClass(item[field.key]) : ''" class="py-2 px-4">
              {{ item[field.key] }}
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script>
import { userLevel } from "@/utils";
import FilterablePaginationMixin from "@/mixins/FilterablePaginationMixin";
import { fetchProducts, fetchProductsByOrganization, fetchProductFilterOptions } from "@/api/products";

export default {
  mixins: [FilterablePaginationMixin], // 引入共用 Mixin
  data() {
    return {
      PAGE_SIZE: 15,
      fields: [
        { key: 'update_time', label: 'UpdateTime' },
        { key: 'organization_name', label: 'Organization' },
        { key: 'modelname', label: 'Model' },
        { key: 'code', label: 'Device' },
        { key: 'status', label: 'Status' },
        { key: 'tablet_name', label: 'Tablet' },
        { key: 'drug_authorization_quantity', label: 'Authorization' },
        { key: 'completed_production_quantity', label: 'Completed' },
      ],
      filterableFields: [
        { key: 'organization_name', label: 'Organization' },
        { key: 'code', label: 'Device' },
        { key: 'tablet_name', label: 'Tablet' },
        { key: 'status', label: 'Status' },
      ],
      filters: {
        organization_name: '',
        modelname: '',
        code: '',
        status: '',
        tablet_name: '',
        start_date: '',
        end_date: '',
      },
    };
  },
  methods: {
    getStatusClass(status) {
      switch (status.toLowerCase()) {
        case 'online':
          return 'text-green-500'; // Online 顯示綠色
        case 'offline':
          return 'text-red-800'; // Offline 顯示紅色
        default:
          return '';
      }
    },
    goToMachineList(productId, serialNumber) {
      this.$router.push({
        name: 'MachineList', params: {
          serialNumber: serialNumber,
          productId: productId, // 將 product ID 傳遞給 machine_page
        },
      });
    },
    async fetchDataFunction(page, queryParams) {
      const user = this.$store.state.user;
      const roleLevel = userLevel(user.role);
      return roleLevel <= 1
        ? await fetchProducts(page, queryParams)
        : await fetchProductsByOrganization(user.organization_id, page, queryParams);
    },
    async fetchFilterOptions() {
      const user = this.$store.state.user;
      const roleLevel = userLevel(user.role);
      try {
        const response = roleLevel <= 1
          ? await fetchProductFilterOptions()
          : await fetchProductFilterOptions(user.organization_id);
        this.filterOptions = response.data;
      } catch (error) {
        console.error("Error fetching filter options:", error.message);
      }
    },
  },
  async mounted() {
    this.loading = true;
    await this.fetchFilterOptions();
    await this.applyFilters();
    this.loading = false;
  },
  computed: {
    sortedItems() {
      return [...this.items].sort((a, b) => {
        const dateA = new Date(a.update_time);
        const dateB = new Date(b.update_time);
        return this.sortOrder === "asc" ? dateA - dateB : dateB - dateA;
      });
    },
  },
};
</script>

<style scoped>
.hover-highlight:hover {
  background-color: #efeeee;
  cursor: pointer;
}
</style>