import axios from "axios";

const debug = false;

const apiClient = axios.create({
    baseURL: debug ? "http://127.0.0.1:8000/api/" : "https://www.stackdose.org/api/",
    timeout: 30000, // 30 seconds  
    //withCredentials: true,  // 攜帶憑證 
    headers: {
        "Content-Type": "application/json",
    },
});
//apiClient.defaults.xsrfCookieName = "csrftoken"; // CSRF Token 的 Cookie 名稱
//apiClient.defaults.xsrfHeaderName = "X-CSRFToken"; // CSRF Token 的 Header 名稱

export default apiClient;
