import apiClient from "./auth.js";
import { getHeaders } from "@/utils.js";

export const fetchOperationFilterOptions = (organization_id = null) => {
  const query = organization_id ? `?organization_id=${organization_id}` : '';
  return apiClient.get(`/operation_logs/filter-options/${query}`);
};

export const fetchProductionFilterOptions = (organization_id = null) => {
  const query = organization_id ? `?organization_id=${organization_id}` : '';
  return apiClient.get(`/productions/filter-options/${query}`);
};

export const fetchOperationsByOrganization = (organization_id, page = 1, queryParams = '') =>
  apiClient.get(`/operation_logs/organization/${organization_id}/?page=${page}${queryParams}`);

export const fetchOperations = (page = 1, queryParams = '') =>
  apiClient.get(`/operation_logs/?page=${page}${queryParams}`);

export const fetchProductions = (page = 1, queryParams = '') =>
  apiClient.get(`/productions/?page=${page}${queryParams}`);

export const fetchProductionsByOrganization = (organization_id, page = 1, queryParams = '') =>
  apiClient.get(`/productions/organization/${organization_id}/?page=${page}${queryParams}`);

export const fetchProductFilterOptions = (organization_id = null) => {
  const query = organization_id ? `?organization_id=${organization_id}` : '';
  return apiClient.get(`/products/filter-options/${query}`);
};

export const fetchEventLogFilterOptions = (organization_id = null) => {
  const query = organization_id ? `?organization_id=${organization_id}` : '';
  return apiClient.get(`/eventlogs/filter-options/${query}`);
};

export const fetchProducts = (page = 1, queryParams = '') =>
  apiClient.get(`/products/?page=${page}${queryParams}`);

export const fetchProductsByOrganization = (organization_id, page = 1, queryParams = '') =>
  apiClient.get(`/products/organization/${organization_id}/?page=${page}${queryParams}`);

export const fetchMaintenanceFilterOptions = (organization_id = null) => {
  const query = organization_id ? `?organization_id=${organization_id}` : '';
  return apiClient.get(`/maintenance/filter-options/${query}`);
};

export const fetchMaintenances = (page = 1, queryParams = '') =>
  apiClient.get(`/maintenance/?page=${page}${queryParams}`);

export const fetchMaintenancesByUser = (user_id, page = 1, queryParams = '') =>
  apiClient.get(`/maintenance/user/${user_id}/?page=${page}${queryParams}`);

export const fetchMaintenancesByOrganization = (organization_id, page = 1, queryParams = '') =>
  apiClient.get(`/maintenance/organization/${organization_id}/?page=${page}${queryParams}`);

export const fetchModelsByProduct = (serialNumber, page = 1, queryParams = '') => {
  return apiClient.get(`/models_by_product/${serialNumber}/?page=${page}${queryParams}`);
};

export const fetchAvailableComponents = (type, includeAll = false) =>
  apiClient.get(`/available-components/filter-options/?type=${type}&include_all=${includeAll}`);

export const getMaintenanceById = (maintenanceId) =>
  apiClient.get(`/maintenance/${maintenanceId}/`);

export const createMaintenance = (requestData) =>
  apiClient.post(`/maintenance/`, requestData, { headers: getHeaders() });

export const updateMaintenance = (maintenanceId, requestData) =>
  apiClient.patch(`/maintenance/${maintenanceId}/`, requestData, { headers: getHeaders() });

export const deleteMaintenance = (maintenanceId) =>
  apiClient.delete(`/maintenance/${maintenanceId}/`, { headers: getHeaders() });

export const createEventLog = (requestData) =>
  apiClient.post(`/eventlogs/`, requestData, { headers: getHeaders() });

export const deleteEventLog = (id) =>
  apiClient.delete(`/eventlogs/${id}`, { headers: getHeaders() });

export const fetchEventLogs = (page = 1, queryParams = '') =>
  apiClient.get(`/eventlogs/?page=${page}${queryParams}`);

export const fetchEventLogsByOrganization = (organization_id, page = 1, queryParams = '') =>
  apiClient.get(`/eventlogs/organization/${organization_id}/?page=${page}${queryParams}`);


export const updateComponentProduct = (serialNumber, productId, type) => {
  return apiClient.patch(
    `/available-components/${serialNumber}/update-product/?type=${type}`,
    { product: productId } // 傳遞要更新的 product ID
  );
};
