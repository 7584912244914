<template>
  <div class="w-full mx-auto mt-0">
    <div class="pagination flex justify-left items-center my-2">
      <button @click="changePage(currentPage - 1)" :disabled="currentPage === 1"
        class="px-4 py-2 mx-1 bg-gray-200 rounded">
        Prev
      </button>
      <span>&nbsp;{{ currentPage }} / {{ totalPages }}&nbsp;</span>
      <button @click="changePage(currentPage + 1)" :disabled="currentPage === totalPages"
        class="px-4 py-2 mx-1 bg-gray-200 rounded">
        Next
      </button>
      <!-- Loading 提示 -->
      <span v-if="loading" class="text-blue-500 font-semibold">&nbsp; Loading... </span>
    </div>
    <!-- 篩選選項 -->
    <div class="flex flex-wrap gap-4 items-end mb-4">
      <div v-for="field in filterableFields" :key="field.key" class="flex flex-col">
        <label :for="field.key" class="font-semibold mb-1">{{ field.label }}</label>
        <select v-model="filters[field.key]" @change="applyFilters" class="border p-2 rounded w-full">
          <option value="">全部</option>
          <option v-for="option in filterOptions[field.key]" :key="option" :value="option">
            {{ option }}
          </option>
        </select>
      </div>
      <!-- 日期範圍選擇 -->
      <div class="flex items-center">
        <label for="start_date" class="font-semibold mr-2">Start Date:</label>
        <input type="date" id="start_date" v-model="filters.start_date" @change="applyFilters"
          class="border p-2 rounded" />
      </div>
      <div class="flex items-center">
        <label for="end_date" class="font-semibold mr-2">End Date:</label>
        <input type="date" id="end_date" v-model="filters.end_date" @change="applyFilters" class="border p-2 rounded" />
      </div>
      <!-- Reset 按鈕 -->
      <button @click="resetFilters" class="px-4 py-2 bg-red-500 text-white rounded hover:bg-red-600 h-10">
        Reset
      </button>
      <!-- 排序按鈕 -->
      <button @click="toggleSortOrder" class="px-4 py-2 bg-blue-500 text-white rounded hover:bg-blue-600 h-10">
        Sort by Created: {{ sortOrder === 'asc' ? '🔼' : '🔽' }}
      </button>
    </div>
    <!-- 表格 -->
    <div class="table-responsive">
      <table class="w-full border-collapse border border-gray-300">
        <thead>
          <tr class="bg-gray-100 text-center">
            <th v-for="field in fields" :key="field.key" class="py-2 px-4">
              {{ field.label }}
            </th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="item in sortedItems" :key="item.id" class="border-b">
            <td v-for="field in fields" :key="field.key" class="py-2 px-4">
              {{ item[field.key] }}
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script>
import FilterablePaginationMixin from "@/mixins/FilterablePaginationMixin";
import { fetchOperations, fetchOperationsByOrganization, fetchOperationFilterOptions } from "@/api/products";

export default {
  mixins: [FilterablePaginationMixin], // 引入共用 Mixin,
  data() {
    return {
      PAGE_SIZE: 15, // 定義常數頁面大小
      fields: [
        { key: 'created_date', label: 'Created' },
        { key: 'log_name', label: 'Log Type' },
        { key: 'level', label: 'Level' },
        { key: 'message', label: 'Message' },
        { key: 'organization_name', label: 'Organization' },
        { key: 'username', label: 'User' },
        { key: 'productname', label: 'Device' },
      ],
      filterableFields: [
        { key: 'log_name', label: 'LogoType' },
        { key: 'level', label: 'Level' },
        { key: 'organization_name', label: 'Organization' },
        { key: 'username', label: 'User' },
        { key: 'productname', label: 'Device' },
      ],
      filters: {
        log_name: '',
        level: '',
        organization_name: '',
        username: '',
        productname: '',
        start_date: '', // 起始日期篩選
        end_date: '',   // 結束日期篩選
      }
    };
  },
  methods: {
    // 定義 API 呼叫函數，傳遞給 Mixin 使用
    async fetchDataFunction(page, queryParams) {
      const user = this.$store.state.user;
      if (user.role === "SUPER") {
        return await fetchOperations(page, queryParams); // 全量數據
      } else {
        return await fetchOperationsByOrganization(user.organization_id, page, queryParams); // 組織範圍數據
      }
    },
    // 獲取篩選選項
    async fetchFilterOptions() {
      const user = this.$store.state.user;
      console.log("Current User:", user); // 確認用戶資訊

      try {
        console.log("Fetching Filter Options..."); // API 請求開始

        const response = user.role === "SUPER"
          ? await fetchOperationFilterOptions()
          : await fetchOperationFilterOptions(user.organization_id);

        console.log("API Response:", response); // 確認 API 回應內容
        this.filterOptions = response.data;
        console.log("Filter Options Set:", this.filterOptions); // 確認資料設置完成

      } catch (error) {
        // 輸出錯誤訊息和完整錯誤對象
        console.error("Error fetching filter options:", error.message);

        // 如果是 axios 錯誤，輸出詳細的 response 資訊
        if (error.response) {
          console.error("Status Code:", error.response.status); // HTTP 狀態碼
          console.error("Response Data:", error.response.data); // 回傳的錯誤訊息
          console.error("Headers:", error.response.headers);    // 回傳的 Headers
        } else if (error.request) {
          console.error("No Response Received:", error.request); // 請求已發送但無回應
        } else {
          console.error("Error Config:", error.config); // 設定錯誤
        }
      }
    },
  },
  async mounted() {
    this.loading = true;
    await this.fetchFilterOptions();
    await this.applyFilters(); // 初始加載資料
    this.loading = false;
  },
  computed: {
    // 排序邏輯，根據 sortOrder 進行排序
    sortedItems() {
      return [...this.items].sort((a, b) => {
        const dateA = new Date(a.created_date);
        const dateB = new Date(b.created_date);
        return this.sortOrder === "asc" ? dateA - dateB : dateB - dateA;
      });
    },
  },
};
</script>
