import Swal from "sweetalert2";
import { logoutUser } from '@/api/users';
import router from "./router";
import store from "./store";
import { createEventLog } from "@/api/products";
// exportToPDF.js
import jsPDF from 'jspdf';
import html2canvas from 'html2canvas';
//import { checkSession } from "@/api/users";

export async function exportAsPDF(datas, titleText = "Default Title") {
    console.log(datas);
    const fileName = prompt("請輸入文件名稱:", "exported-table");
    if (fileName) {
        await exportDataToPDF(datas, `${fileName}.pdf`, titleText);
    }
}

export async function saveEventLog(userid, eventType, action, targetType, targetName, message, result, details = null) {
    try {
        var eventLog = {
            user: userid,
            event_type: eventType, // 事件類型
            action: action, // 行為
            target_type: targetType, // 目標類型（可選）
            target_name: targetName, // 目標名稱
            message: message, // 操作訊息
            details: details,
            result: result, // 結果
        };

        const response = await createEventLog(eventLog); // 調用 API
        console.log("Event log created:", response);
    } catch (error) {
        console.error("Failed to create event log:", error);
    }
}

export async function exportDataToPDF(datas, fileName, titleText) {
    if (!datas || datas.length === 0) {
        return;
    }

    let tempTable = document.createElement('table');
    tempTable.style.border = '1px solid black';
    tempTable.style.borderCollapse = 'collapse';

    const headers = Object.keys(datas[0]);
    let headerHtml = '<thead><tr>';
    headers.forEach(header => {
        headerHtml += `<th style="border: 1px solid black; padding:5px;">${header}</th>`
    });

    headerHtml += '</tr></thead>';
    tempTable.innerHTML = headerHtml;
    let bodyHtml = '<tbody>';
    datas.forEach(data => {
        bodyHtml += '<tr>';
        headers.forEach(header => {
            bodyHtml += `<td style="border: 1px solid black;padding:5px;text-align:center">${data[header]}</td>`;
        });
        bodyHtml += '</tr>';
    });
    bodyHtml += '</tbody>';

    tempTable.innerHTML += bodyHtml;
    document.body.appendChild(tempTable);

    await exportTableToPDF(tempTable, fileName, titleText);
    tempTable.remove();
}

export async function exportTableToPDF(element, fileName, titleText) {
    const pdf = new jsPDF({
        orientation: 'landscape',
        unit: 'mm',
        format: 'a4'
    });

    const rowsPerPage = 18;
    const rows = element.rows;
    let currentRow = 0;

    while (currentRow < rows.length) {
        let tempDiv = document.createElement('div');

        if (currentRow === 0) {
            let titleElement = document.createElement('h1');
            titleElement.style.textAlign = 'left';
            titleElement.style.fontSize = '36px';
            titleElement.style.marginTop = '10mm';
            titleElement.style.marginBottom = '10mm';
            titleElement.textContent = titleText;
            tempDiv.appendChild(titleElement);
        }

        let tempTable = document.createElement('table');
        tempTable.style.border = '1px solid black';
        tempTable.style.borderCollapse = 'collapse';

        if (currentRow === 0) {
            tempTable.appendChild(rows[0].cloneNode(true));
        }

        for (let i = 0; i < rowsPerPage && currentRow < rows.length; i++) {
            if (!(i === 0 && currentRow === 0)) {
                tempTable.appendChild(rows[currentRow].cloneNode(true));
            }
            currentRow++;
        }

        tempDiv.appendChild(tempTable);
        document.body.appendChild(tempDiv);
        const canvas = await html2canvas(tempDiv);
        const imgData = canvas.toDataURL('image/png');
        tempTable.remove();

        if (currentRow > rowsPerPage) {
            pdf.addPage();
        }

        pdf.addImage(imgData, 'PNG', 10, 10);
    }

    // 保存 PDF
    pdf.save(fileName);
}

let logoutTimer;

export function currentDate(dateString = null) {
    let date = new Date();
    if (dateString != null) {
        date = new Date(dateString);
    }
    return date.toISOString().split('T')[0] // 格式化為 YYYY-MM-DD
}

export function formatKey(key) {
    // 將鍵轉換為單詞，首字母大寫，並以空格分隔
    return key
        .replace(/([A-Z])/g, ' $1') // 在大寫字母前加空格
        .replace(/_/g, ' ') // 將下劃線替換為空格
        .replace(/^(.)/, (str) => str.toUpperCase()); // 首字母大寫

}

export function userLevel(role) {
    if (role == "SUPER") {
        return 0;
    } else if (role == "SUPER ADMIN") {
        return 1;
    } else if (role == "ADMIN") {
        return 2;
    } else if (role == "MG") {
        return 3;
    }
    return 4;
}


let sessionCheckInterval = null;  // 用於存儲 Session 檢查計時器
export function startLogoutTimer() {
    const TIMEOUT = 60 * 60 * 1000;  // 1 小時
    //const CHECK_INTERVAL = 30000;  // 3 秒檢查一次

    // 設置登出超時計時器
    logoutTimer = setTimeout(async () => {
        const user = localStorage.getItem('user');
        if (user) {
            await handleLogout();
        }
        clearTimeout(logoutTimer);
    }, TIMEOUT);

    // // 設置定期檢查 Session 狀態的計時器
    // sessionCheckInterval = setTimeout(async () => {
    //     const user = localStorage.getItem('user'); // 或檢查 Vuex 狀態        
    //     if (!user) {
    //         console.log("User already logged out, stopping session check.");
    //         clearTimeout(sessionCheckInterval);  // 用戶已登出，停止檢查
    //     } else {
    //         try {
    //             const response = await checkSession();
    //             console.log(response);
    //             if (!response.data.is_authenticated) {
    //                 console.log("logout!");
    //                 await handleLogout();
    //                 clearTimeout(sessionCheckInterval);  // 停止檢查
    //             }
    //         } catch (error) {
    //             console.error("Error during session check:", error);
    //         }
    //     }
    // }, CHECK_INTERVAL);
}

export async function handleLogout() {
    try {
        const response = await logoutUser();
        const user = store.state.user;

        if (response.data.success) {
            // 保存登出事件日誌
            await saveEventLog(
                user.id,
                "User Management",
                "Logout",
                "User",
                user.username,
                "User Logout Successful",
                "Success"
            );

            // 清理定時器
            if (logoutTimer) clearTimeout(logoutTimer);
            if (sessionCheckInterval) clearTimeout(sessionCheckInterval);

            // 重置應用狀態並跳轉到登錄頁
            store.commit('logout');
            router.push({ name: "Login" });
        } else {
            console.error(response.data.message);
        }
    } catch (error) {
        console.error('Error during logout:', error);
    }
}

export function resetLogoutTimer() {
    if (logoutTimer) clearTimeout(logoutTimer);
    if (sessionCheckInterval) clearTimeout(sessionCheckInterval);
    startLogoutTimer();
}

export function showErrorMessage(title, message) {
    Swal.fire({
        icon: "error",
        title: title,
        text: message,
    });
}

export function getCurrentDateTime() {
    const now = new Date();
    const utcOffset = now.getTimezoneOffset() * 60000;
    const timeZoneOffset = new Date(now.getTime() - utcOffset);
    const timeZoneDate = new Date(timeZoneOffset.getTime());

    return timeZoneDate.toISOString().slice(0, 19).replace('T', ' ');
}


export function showSuccessMessage(title, message) {
    Swal.fire({
        icon: "success",
        title: title,
        text: message,
    });
}


export function showWarningWithConfirmation(title, confirmText) {
    return Swal.fire({
        title: "Are you sure?",
        text: title,
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: confirmText,
    });
}

export function getCookie(name) {
    // A helper method to get CSRF token from cookies
    const value = `; ${document.cookie}`;
    const parts = value.split(`; ${name}=`);
    const cookie = parts.pop().split(";").shift();
    if (parts.length === 2) return cookie;
}

export function getHeaders(needsToken = true) {
    const baseHeaders = { "Content-Type": "application/json" };

    if (needsToken) {
        return {
            ...baseHeaders,
            "X-CSRFToken": getCookie("csrftoken")
        };
    }

    return baseHeaders;
}
