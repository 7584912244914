<template>
  <div class="w-full mx-auto mt-1">
    <!-- Loading 提示 -->
    <div class="pagination flex justify-left items-center my-2">
      <button @click="changePage(currentPage - 1)" :disabled="currentPage === 1"
        class="px-4 py-2 mx-1 bg-gray-200 rounded">
        Prev
      </button>
      <span>&nbsp;{{ currentPage }} / {{ totalPages }}&nbsp;</span>
      <button @click="changePage(currentPage + 1)" :disabled="currentPage === totalPages"
        class="px-4 py-2 mx-1 bg-gray-200 rounded">
        Next
      </button>
      <span v-if="loading" class="text-blue-500 font-semibold">&nbsp; Loading... </span>
    </div>

    <!-- 篩選選項 -->
    <div class="flex flex-wrap gap-4 items-end mb-4">
      <div v-for="field in filterableFields" :key="field.key" class="flex flex-col">
        <label :for="field.key" class="font-semibold mb-1">{{ field.label }}</label>
        <select v-model="filters[field.key]" @change="applyFilters" class="border p-2 rounded w-full">
          <option value="">全部</option>
          <option v-for="option in filterOptions[field.key]" :key="option" :value="option">
            {{ option }}
          </option>
        </select>
      </div>
      <!-- 日期範圍選擇 -->
      <div class="flex items-center">
        <label for="start_date" class="font-semibold mr-2">Start Date:</label>
        <input type="date" id="start_date" v-model="filters.start_date" @change="applyFilters"
          class="border p-2 rounded" />
      </div>
      <div class="flex items-center">
        <label for="end_date" class="font-semibold mr-2">End Date:</label>
        <input type="date" id="end_date" v-model="filters.end_date" @change="applyFilters" class="border p-2 rounded" />
      </div>
      <!-- Reset 按鈕 -->
      <button @click="resetFilters" class="px-4 py-2 bg-red-500 text-white rounded hover:bg-red-600 h-10">
        Reset
      </button>
      <!-- 排序按鈕 -->
      <button @click="toggleSortOrder('report_time')"
        class="px-4 py-2 bg-blue-500 text-white rounded hover:bg-blue-600 h-10">
        Sort by Report Time: {{ sortOrder === 'asc' ? '🔼' : '🔽' }}
      </button>
    </div>

    <!-- 表格 -->
    <div class="table-responsive">
      <table class="w-full border-collapse border border-gray-300">
        <thead>
          <tr class="bg-gray-100 text-center">
            <th v-for="field in fields" :key="field.key" class="py-2 px-4">
              {{ field.label }}
            </th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="item in sortedItems" :key="item.id" class="border-b">
            <td v-for="field in fields" :key="field.key" class="py-2 px-4">
              <template v-if="field.key === 'functions'">
                <button :disabled="!canShowActionButtons()" @click="handleUpdate(item)"
                  class="px-2 py-1 bg-green-500 text-white rounded hover:bg-green-600 disabled:bg-gray-400">
                  Update
                </button>
                <button :disabled="!canShowActionButtons()" @click="handleDelete(item)"
                  class="px-2 py-1 bg-red-500 text-white rounded hover:bg-red-600 ml-2 disabled:bg-gray-400">
                  Delete
                </button>
              </template>
              <template v-else>
                {{ item[field.key] }}
              </template>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script>
import FilterablePaginationMixin from "@/mixins/FilterablePaginationMixin";
import { saveEventLog } from "@/utils";
import { fetchMaintenances, fetchMaintenancesByOrganization, deleteMaintenance, fetchMaintenanceFilterOptions } from "@/api/products";

export default {
  mixins: [FilterablePaginationMixin],
  data() {
    const PAGE_SIZE = 15;
    const fields = [
      { key: 'report_time', label: 'Report Time', isFilterable: false },
      { key: 'organization_name', label: 'Organization' },
      { key: 'reporter_name', label: 'Reporter' },
      { key: 'product_name', label: 'Product' },
      { key: 'status', label: 'Status' },
      { key: 'technician_name', label: 'Technician' },
      { key: 'functions', label: 'Functions', isFilterable: false }, // 不需要篩選的欄位
    ];

    // 自動生成 `filterableFields`
    const filterableFields = fields
      .filter(field => field.isFilterable !== false)
      .map(({ key, label }) => ({ key, label }));

    // 自動生成 `filters`
    const filters = filterableFields.reduce((acc, field) => {
      acc[field.key] = '';
      return acc;
    }, { start_date: '', end_date: '' });

    return {
      PAGE_SIZE, // 頁面大小
      fields,
      filterableFields,
      filters
    };
  },
  methods: {
    async fetchDataFunction(page, queryParams) {
      const user = this.$store.state.user;
      return user.role === "SUPER"
        ? await fetchMaintenances(page, queryParams)
        : await fetchMaintenancesByOrganization(user.organization_id, page, queryParams);
    },
    async fetchFilterOptions() {
      const user = this.$store.state.user;
      try {
        const response = user.role === "SUPER"
          ? await fetchMaintenanceFilterOptions()
          : await fetchMaintenanceFilterOptions(user.organization_id);
        this.filterOptions = response.data;
        console.log(this.filterOptions);

      } catch (error) {
        console.error("Error fetching filter options:", error.message);
      }
    },
    async handleDelete(item) {
      console.log("item", item);
      const isConfirmed = confirm("Are you sure you want to delete this maintenance record?");
      if (!isConfirmed) return;
      let success;
      try {
        this.loading = true;
        await deleteMaintenance(item.id);
        this.applyFilters(); // 刪除後重新加載數據
        success = true;
      } catch (error) {
        console.error("Error deleting maintenance record:", error.message);
        success = false;
      } finally {
        this.loading = false;
        const userid = this.$store.state.user.id;
        await saveEventLog(
          userid,
          "Maintenance Management",
          "Delete",
          "Maintenance",
          null,
          success ? "Delete Maintenance Failed." : "Maintenance Delete Successful",
          success ? "Success" : "Failed"
        );
      }
    },
    async handleUpdate(item) {
      var success;
      const userid = this.$store.state.user.id;
      try {
        this.$store.commit('setSelectedItemId', item.id);
        this.$router.push({
          name: 'UpdateMaintenance',
          params: { maintenanceId: item.id }
        });
        success = true;
      } catch (error) {
        console.error('Error navigating to Maintenance update page:', error);
        success = false;
      }

      await saveEventLog(
        userid,
        "Update Maintenance",
        item.id,
        success ? "Update Maintenance successful." : "Update Maintenance failed.",
        success);
    },
    canShowActionButtons() {
      const allowedRoles = ["SUPER", "SUPER ADMIN", "ADMIN"];
      const userRole = this.$store.state.user.role;
      return allowedRoles.includes(userRole);
    }
  },
  async mounted() {
    this.loading = true;
    await this.fetchFilterOptions();
    await this.applyFilters();
    this.loading = false;
  },
  computed: {
    sortedItems() {
      return [...this.items].sort((a, b) => {
        const dateA = new Date(a.report_time);
        const dateB = new Date(b.report_time);
        return this.sortOrder === "asc" ? dateA - dateB : dateB - dateA;
      });
    },
  },
};
</script>

<style scoped>
@import '@/assets/css/table-styles.css';
</style>
