import { createRouter, createWebHistory } from "vue-router";
import MasterDashboard from "../pages/master/MasterDashboard";
import PageProfile from "../pages/PageProfile";
import PageMaintenance from "../pages/PageMaintenance";
import PageMaintenanceList from "../pages/PageMaintenanceList";
import PageLogin from "../pages/PageLogin";
import PageRegister from "../pages/PageRegister";
import PageUserList from "../pages/PageUserList";
import PageUpdateUser from "../pages/PageUpdateUser";
import PageProductList from "../pages/PageProductList";
import PageOperationList from "../pages/PageOperationList";
import PageProductionList from "../pages/PageProductionList";
import PageSettings from "../pages/PageSettings";
import PageDownloads from "../pages/PageDownloads";
import PageMachineList from "../pages/PageMachineList";
import PageMaintenanceDetail from "../pages/PageMaintenanceDetail";

import store from '../store';
import PageEventLogList from "@/pages/PageEventLogList.vue";

const routes = [
    {
        name: "MasterDashboard",
        path: "/",
        component: MasterDashboard,
        children: [
            {
                name: "Profile",
                path: "profile",
                component: PageProfile,
            },
            {
                name: "Login",
                path: "login",
                component: PageLogin,
            },
            {
                name: "Register",
                path: "register",
                component: PageRegister,
            },
            {
                name: "UserList",
                path: "users",
                component: PageUserList,
            },
            {
                name: "ProductList",
                path: "products",
                component: PageProductList,
            },
            {
                name: "OperationList",
                path: "operations",
                component: PageOperationList,
            },
            {
                name: "EventLogList",
                path: "eventLogs",
                component: PageEventLogList,
            },
            {
                name: "ProductionList",
                path: "productions",
                component: PageProductionList,
            },
            {
                name: "MachineList",
                path: "machines/:serialNumber/:productId",
                component: PageMachineList,
            },
            {
                name: "UpdateUser",
                path: "update-user/:userId",
                component: PageUpdateUser,
            },
            {
                name: "Maintenance",
                path: "maintenance",
                component: PageMaintenance,
            },
            {
                name: "UpdateMaintenance",
                path: "update-maintenance/:maintenanceId",
                component: PageMaintenance,
            },
            {
                name: "MaintenanceList",
                path: "maintenanceList",
                component: PageMaintenanceList,
            },
            {
                name: "PageMaintenanceDetail",
                path: "pageMaintenanceDetail",
                component: PageMaintenanceDetail,
            },
            {
                name: "Settings",
                path: "settings",
                component: PageSettings,
            },
            {
                name: "Downloads",
                path: "downloads",
                component: PageDownloads,
            },
        ],
    },
];

const router = new createRouter({
    history: createWebHistory(),
    routes,
});

import { saveEventLog } from "@/utils";
const routesRequiringNoAuth = ["Login"];
const routesAccessibleToAll = ["Settings", "Downloads"];

router.beforeEach(async (to, from, next) => {
    // 檢查用戶是否登入
    const loggedIn = !!store.state.user && Object.keys(store.state.user).length > 0;
    const userid = store.state.user.id || null; // 確保獲取當前用戶 ID
    const user = store.state.user;
    console.log(userid, user);

    // 如果用戶已登入且發生導航
    if (loggedIn) {
        try {
            await saveEventLog(
                userid,
                "Navigation",
                from.name,
                to.name,
                to.name,
                `Navigating to ${to.fullPath}`,
                "Success",
                `From: ${from.name || "Unknown"} To: ${to.name || "Unknown"}`
            );
        } catch (error) {
            console.error("Failed to save navigation log:", error.message);
        }
    }
    // 如果已經登入在嘗試訪問首頁，則重定向到Profile
    if (loggedIn && to.path === '/') {
        next({ name: 'Profile' });
    }
    // 如果訪問的是Profile且用戶未登入，則重定向到Login
    else if (!loggedIn && !routesRequiringNoAuth.includes(to.name)
        && !routesAccessibleToAll.includes(to.name)) {
        next({ name: 'Login' });
    } else {
        next();
    }
});

export default router;
