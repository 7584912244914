<template>
  <div class="w-full flex justify-center">
    <div class="w-full sm:w-1/2 h-3/4 rounded p-6">
      <form id="update-form" method="POST" @submit.prevent="handleUpdate"
        class="flex flex-col px-10 py-6 items-center rounded-xl">
        <h2 class="text-blue-700 text-3xl font-semibold my-4">User Update</h2>
        <label for="username" class="text-sm">Username:</label>
        <input type="text" name="username" autocomplete="off" v-model="update_user.username"
          class="text-center h-8 w-full rounded-md border border-slate-300 text-sm  bg-transparent outline-blue-600 shadow-sm mb-4"
          required>
        <br>
        <label for="email">Email:</label>
        <input type="email" name="email" v-model="update_user.email"
          class="text-center h-8 w-full rounded-md border border-slate-300 text-sm  bg-transparent outline-blue-600 shadow-sm mb-4"
          required>
        <br>
        <label for="organization">Training Cert ID:</label>
        <div class="text-sm flex w-full justify-between mb-4 mt-4">
          <div class="w-full pr-2">
            <input id="trainingCertId" type="text" name="training_cert"
              class="text-center h-8 w-full rounded-md border border-slate-300 text-sm bg-transparent outline-blue-600 shadow-sm"
              v-model="training_cert" :disabled="isDisabled">
          </div>
        </div>
        <div>
          <label for="organization">Organization:</label>

          <select v-model="selectedOrganization" class="text-sm mx-1 py-2 px-4 mb-4" required>
            <option disabled value=""></option>
            <option v-for="organization in organizations" :key="organization.id" :value="organization.id">
              {{ organization.name }}
            </option>
          </select>

        </div>
        <label for="role">Role:</label>
        <div v-if="roles">
          <div class="role-selector flex">
            <div v-for="role in roles" :key="role" class="role-option text-sm mx-1 px-4">
              <input type="radio" :id="role" :value="role" v-model="update_user.role">
              <label :for="role">{{ role }}</label>
            </div>
          </div>
        </div>
        <div v-else>
          <span class="text-sm"> {{ update_user.role }}</span>
        </div>
        <div class="grid grid-cols-2 gap-4 justify-center w-full">
          <button type="submit"
            class="bg-blue-700 h-10 cursor-pointer text-white rounded-md hover:bg-blue-600 hover:outline outline-2 outline-blue-600 outline-offset-2 text-sm">Update</button>
          <button type="button" @click="goBack"
            class="bg-gray-600 h-10 cursor-pointer text-white rounded-md hover:bg-red-600 hover:outline outline-2 outline-yellow-300 outline-offset-2 text-sm">Back</button>
        </div>
      </form>
    </div>
  </div>
</template>
<script>
import { getUser, updateUser, fetchTrainingCertId } from '@/api/users.js';
import { showErrorMessage, showSuccessMessage, userLevel, saveEventLog } from '@/utils';
import { ROLES } from "@/constants.js";
import fetchOrganizationsMixin from '@/mixins/fetchOrganizationsMixin';

export default {
  mixins: [fetchOrganizationsMixin],
  data() {
    return {
      isDisabled: false,
      selectedOrganization: null,
      organizations: [],
      organization_name: "",
      training_cert: "",
      userRole: "",
      roles: ROLES,
      update_user: {
        username: '',
        email: '',
        training_cert: '',
        organization: '',
        organization_name: '',
        role: ''
      },
      original_user: {}, // 新增存儲初始值的變數
    }
  },
  computed: {
    currentUserId() {
      return this.$store.state.user.id;
    },
  },
  methods: {
    adjustRolesBasedOnUserLevel() {
      const level = userLevel(this.$store.state.user.role);
      if (level === 0) {
        this.roles = this.roles.filter(role => role !== 'SUPER');
      } else if (level === 1) {
        this.roles = this.roles.filter(role => role !== 'SUPER' && role !== 'SUPER ADMIN');
      }
      else if (level === 2) {
        this.roles = this.roles.filter(role => role !== 'SUPER' && role !== 'ADMIN' && role !== 'SUPER ADMIN');
      }
    },
    goBack() {
      this.$router.go(-1);
    },
    async handleUpdate() {
      let requiresTrainingCertUpdate = true;
      let trainingCert = null;
      this.update_user.organization = this.selectedOrganization;
      this.isLoading = true;
      try {
        if (this.training_cert) {
          // 證書相同則不更新
          if (this.update_user.training_cert == this.training_cert) {
            requiresTrainingCertUpdate = false;
          } else {
            //取得證書
            const response = await fetchTrainingCertId(this.training_cert);
            trainingCert = response.data;
            // 如果已經有占用
            if (trainingCert.is_assigned) {
              showErrorMessage("Update Failed", "TrainingCertId is already in use.");
              return;
            }
          }
        }
      } catch (error) {
        showErrorMessage("Failed to Update TrainingCertId", "An error occurred during the update process.");
        return;
      } finally {
        this.isLoading = false;
      }

      this.isLoading = true;
      try {
        //更新證書
        if (requiresTrainingCertUpdate) {
          //證書清除
          if (!this.training_cert) {
            if (this.update_user.training_cert) {
              this.update_user.training_cert = null;
            }
          } else {
            this.update_user.training_cert = trainingCert.id;
          }
        }

        const changedFields = [];
        for (const key in this.update_user) {
          if (this.update_user[key] !== this.original_user[key]) {
            changedFields.push({ field: key, oldValue: this.original_user[key], newValue: this.update_user[key] });
          }
        }

        console.log(changedFields);
        // 將 changedFields 組合成字串
        const changedFieldsString = changedFields
          .map(
            (change) =>
              `${change.field}: '${change.oldValue || "null"}' -> '${change.newValue || "null"}'`
          )
          .join(", ");

        console.log("Changed Fields String:", changedFieldsString);

        if (changedFields.length == 0) {
          return;
        }

        const response = await updateUser(this.update_user);
        if (response.data.success) {
          const userData = response.data.data;
          if (this.currentUserId == userData.id) {
            userData.organization = userData.organization_name;
            this.$store.commit('updateUser', userData);
          }
          await saveEventLog(
            this.currentUserId,
            "User Management",
            "Update",
            "User",
            this.update_user.username,
            "User Update Successful",
            "Success",
            changedFieldsString
          );

          showSuccessMessage("User Update Successful", "you have successfully updated the user information!");
          this.$router.push({ name: 'UserList' });
        } else {
          showErrorMessage("Update Failed", "An error occurred during the update process.");
        }
      } catch (error) {
        if (error.response && error.response.data) {
          // 相同姓名(不能相同)
          if ("username" in error.response.data) {
            showErrorMessage("Update Failed", error.response.data["username"][0]);
          } else {
            showErrorMessage("Update Failed", "An error occurred during the update process.");
          }
        } else {
          showErrorMessage("Update Failed", "An error occurred during the update process.");
        }

        await saveEventLog(
          this.currentUserId,
          "UpdateUser",
          this.update_user.username,
          "User Update Failed",
          false);

      } finally {
        this.isLoading = false;
      }
    }
  }
  ,
  async mounted() {
    //目前登入使用者的權限
    const user = this.$store.state.user;
    const level = userLevel(user.role);

    try {
      this.isLoading = true;
      //讀取組織
      await this.fetchOrganizations();
      //取得更新使用者資訊
      const userId = this.$route.params.userId;
      const response = await getUser(userId);
      this.update_user = response.data;
      this.original_user = { ...response.data }; // 複製原始值
      console.log(this.original_user);

      //設定欄位資料
      this.training_cert = this.update_user.training_cert;
      //設定Training Cert ID(非SUPER/Super Admin則不能再變更)   
      if (this.training_cert && level >= 2) {
        this.isDisabled = true;
      }

      // 獲取當前用戶的 organization_id
      this.selectedOrganization = this.update_user.organization_id;
      this.organization_name = this.update_user.organization_name;
      // 過濾出與當前用戶組織匹配的 organization     
      if (level >= 2) {
        this.organizations = this.organizations.filter(org => org.id === this.selectedOrganization);
      }
      this.adjustRolesBasedOnUserLevel();
    } catch (e) {
      showErrorMessage("Updating Failed", "Error parsing user data.");
    } finally {
      this.isLoading = false;
    }
  }
};
</script>

<style>
@media (max-width: 450px) {
  #update-form {
    padding: 5px;
    font-size: 0.875rem;
  }

  #update-form input,
  #update-form select,
  #update-form textarea {
    height: 6vh;
    font-size: 0.875rem;
  }


  #update-form button,
  #update-form input[type="button"] {
    padding: 8px;
    font-size: 0.875rem;
  }
}
</style>
